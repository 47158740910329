

























































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import serviceCategoryStoreModule from "@/store/modules/serviceCategory";
import { Business, Role, ServiceCategory } from "@/types";
import ServiceCategoryForm from "@/components/service/ServiceCategoryForm.vue";

const {
  mapActions: serviceCategoryActions,
  mapGetters: serviceCategoryGetters,
} = createNamespacedHelpers("SERVICE_CATEGORY_LIST");

export default Vue.extend<any, any, any, any>({
  components: { ServiceCategoryForm },
  name: "Services",
  data: () => ({
    addCategoryDialog: false,
    loadingcategories: false,
    exporting: false,
    serviceCategory: undefined as undefined | ServiceCategory,
    updateDialog: false,
    deleteDialog: false,
    name: "",
    nameRules: [(v: string) => !!v || "Name is required"],
    valid: false,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
  }),
  created() {
    this.loadingcategories = true;
    this.fetchCategories();
  },
  computed: {
    ...serviceCategoryGetters(["serviceCategoryPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchCategories();
      },
      deep: true,
    },
    role: "fetchCategories",
  },
  methods: {
    ...serviceCategoryActions([
      "fetchServiceCategoryList",
      "deleteServiceCategory",
      "updateServiceCategory",
    ]),
    closeModal() {
      this.addCategoryDialog = false;
      this.loadingcategories = true;
      this.fetchCategories();
    },
    fetchCategories() {
      if (!this.role) return;
      const bid = (this.role.business as Business)._id;
      const page = this.options.page || 1;
      this.fetchServiceCategoryList(`?businessId=${bid}&page=${page}`).then(
        () => {
          this.loadingcategories = false;
        }
      );
    },
    editCategory(category: ServiceCategory) {
      this.serviceCategory = category;
      this.name = category.name;
      this.valid = false;
      this.updateDialog = true;
    },
    validateUpdate() {
      (
        this.$refs.updateForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (this.valid) {
        this.updateServiceCategory({
          id: this.serviceCategory?._id,
          serviceCategory: {
            name: this.name,
          },
        }).then((cat) => {
          if (cat) {
            this.updateDialog = false;
            this.valid = false;
            this.name = "";
          }
        });
      }
    },
    deleteCategory(category: ServiceCategory) {
      this.deleteServiceCategory(category._id).then((cat) => {
        if (cat) {
          this.fetchCategories();
          this.deleteDialog = false;
        }
      });
    },
  },
  beforeCreate() {
    const namespace = `SERVICE_CATEGORY_LIST`;
    if (!this.$store.hasModule(namespace)) {
      this.$store.registerModule(namespace, serviceCategoryStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SERVICE_CATEGORY_LIST");
  },
});
