



















































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import { Business, Role, Service, ServicePackage } from "@/types";
import ServiceCategoryForm from "@/components/service/ServiceCategoryForm.vue";
import servicePackageStoreModule from "@/store/modules/servicePackage";
import serviceStoreModule from "@/store/modules/service";
import serviceCategoryStoreModule from "@/store/modules/serviceCategory";

const { mapActions: servicePackageActions, mapGetters: servicePackageGetters } =
  createNamespacedHelpers("PACKAGES_LIST");

const {
  mapActions: serviceCategoryActions,
  mapGetters: serviceCategoryGetters,
} = createNamespacedHelpers("SERVICE_CATEGORY");

const { mapActions: serviceActions, mapGetters: serviceGetters } =
  createNamespacedHelpers("SERVICE_");

export default Vue.extend<any, any, any, any>({
  //Deploy
  components: { ServiceCategoryForm },
  name: "PackagesList",
  data: () => ({
    currency: "KES",
    addPackageDialog: false,
    loadingpackages: false,
    exporting: false,
    servicePackage: undefined as undefined | ServicePackage,
    updateDialog: false,
    deleteDialog: false,
    availableFor: "",
    availableForRules: [(v: string) => !!v || "Name is required"],
    name: "",
    nameRules: [(v: string) => !!v || "Name is required"],
    price: "",
    priceRules: [(v: number) => !!v || "Price is required"],
    description: "",
    serviceCategoryId: "",
    categoryIds: "",
    categoryId: "",
    categoryRules: [(v: string) => !!v || "Category field is required"],
    serviceId: "",
    service: "",
    services: [] as Service[],
    valid: false,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },

      {
        text: "Category",
        align: "start",
        sortable: true,
        value: "categories",
      },

      {
        text: "Applied To",
        align: "start",
        sortable: true,
        value: "availableFor",
      },

      {
        text: "Price",
        align: "start",
        sortable: true,
        value: "price",
      },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
  }),
  created() {
    this.loadingpackages = true;
    this.fetchPackages();
    this.fetchServices();
    this.fetchServiceCategoryList();
  },
  computed: {
    ...serviceGetters(["servicePage"]),
    ...servicePackageGetters(["servicePackagePage"]),
    ...serviceCategoryGetters(["serviceCategoryPage"]),
    ...serviceCategoryGetters(["serviceCategoryPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchPackages();
      },
      deep: true,
    },
    role: "fetchPackages",
  },
  methods: {
    ...servicePackageActions([
      "fetchServicePackageList",
      "deleteServicePackage",
      "updateServicePackage",
    ]),
    ...serviceActions(["fetchServiceList"]),
    ...serviceCategoryActions(["fetchServiceCategoryList"]),
    closeModal() {
      this.addPackageDialog = false;
      this.loadingpackages = true;
      this.fetchPackages();
    },
    async fetchPackages() {
      if (!this.role) return;
      const bid = (this.role.business as Business)._id;
      const page = this.options.page || 1;
      const limit = this.options.limit || 10;
      this.fetchServicePackageList(
        `?businessId=${bid}&page=${page}&limit=${limit}`
      ).then(() => {
        this.loadingpackages = false;
      });
    },
    async fetchServices() {
      if ((this.role.business as Business)._id) {
        const businessId = (this.role.business as Business)._id;
        this.fetchServiceList(`?businessId=${businessId}`);
      }
    },
    editCategory(category: ServicePackage) {
      this.servicePackage = category;
      this.currency = "KES";
      this.name = category.name;
      this.services = category.services;
      this.availableFor = category.availableFor;
      this.description = category.description;
      this.price = category.price;
      this.serviceIds = category.services;
      this.categoryIds = category.categories;
      this.valid = false;
      this.updateDialog = true;
    },
    validateUpdate() {
      (
        this.$refs.updateForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (this.valid) {
        this.updateServicePackage({
          id: this.servicePackage?._id,
          servicePackage: {
            name: this.name,
            availableFor: this.availableFor,
            description: this.description,
            price: this.price,
            serviceIds: this.services.map((item) => item._id),
            categoryIds: this.categoryIds.map((item) => item._id),
          },
        }).then((cat) => {
          this.updateDialog = false;
          if (cat) {
            this.updateDialog = false;
            this.valid = false;
            this.name = "";
          }
        });
      }
    },
    deletePackage(category: ServicePackage) {
      this.deleteServicePackage(category._id).then((cat) => {
        if (cat) {
          this.fetchPackages();
          this.deleteDialog = false;
        }
      });
    },
  },
  beforeCreate() {
    const namespace = `PACKAGES_LIST`;
    if (!this.$store.hasModule(namespace)) {
      this.$store.registerModule(namespace, servicePackageStoreModule);
    }

    if (!this.$store.hasModule("SERVICE_CATEGORY")) {
      this.$store.registerModule(
        "SERVICE_CATEGORY",
        serviceCategoryStoreModule
      );
    }

    if (!this.$store.hasModule("SERVICE_")) {
      this.$store.registerModule("SERVICE_", serviceStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("PACKAGES_LIST");
  },
});
