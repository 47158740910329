

















































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import serviceCategoryStoreModule from "@/store/modules/serviceCategory";
import serviceStoreModule from "@/store/modules/service";
import { Business, Role, Service } from "@/types";
const { mapActions: serviceCategoryActions } = createNamespacedHelpers(
  "SERVICE_CATEGORY_FORM"
);

const { mapActions: serviceActions, mapGetters: serviceGetters } =
  createNamespacedHelpers("CATEGORY_FORM_SERVICE_LIST");

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;
export default Vue.extend<any, any, any, any>({
  name: "ServiceCategoryForm",
  data: () => ({
    category: "",
    categoryRules: [(v: string) => !!v || "Category Name is reguired"],
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
    selectedServiceIds: [] as string[],
    selectedServices: [] as Service[],
    apiUrl: VUE_APP_API_URL,
  }),
  watch: {
    role: "fetchServices",
    options: {
      handler() {
        this.fetchServices();
      },
      deep: true,
    },
  },
  created() {
    this.fetchServices();
  },
  computed: {
    ...serviceGetters(["servicePage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...serviceCategoryActions(["createServiceCategory"]),
    ...serviceActions(["fetchServiceList", "bulkUpdateServices"]),
    validateCategory() {
      const valid = (
        this.$refs.categoryForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;
      const businessId = (this.role.business as Business)._id;
      this.createServiceCategory({ name: this.category, businessId }).then(
        (category) => {
          if (category && this.selectedServices.length)
            this.bulkUpdateServices({
              data: this.selectedServices.map((s) => ({
                serviceId: s._id,
                update: {
                  categories: [
                    ...s.categories.map((c) => c._id),
                    ...[category._id],
                  ],
                },
              })),
            }).then((services: Service[]) => {
              if (services) {
                this.$emit("data-saved", category);
              }
            });
          else this.$emit("data-saved", category);
        }
      );
    },
    fetchServices() {
      if (this.role) {
        const params = `?businessId=${
          (this.role.business as Business)._id
        }&page=${this.options.page || 1}`;
        this.fetchServiceList(params);
      }
    },
    selectService() {
      this.selectedServices = this.servicePage.docs.filter((s: Service) =>
        this.selectedServiceIds.includes(s._id)
      );
    },
    resetForm() {
      this.category = "";
      this.selectedServices = [];
      this.selectedServiceIds = [];
      (
        this.$refs.categoryForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
  },
  beforeCreate() {
    const namespace = `SERVICE_CATEGORY_FORM`;
    if (!this.$store.hasModule(namespace)) {
      this.$store.registerModule(namespace, serviceCategoryStoreModule);
    }
    if (!this.$store.hasModule("CATEGORY_FORM_SERVICE_LIST")) {
      this.$store.registerModule(
        "CATEGORY_FORM_SERVICE_LIST",
        serviceStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SERVICE_CATEGORY_FORM");
    this.$store.unregisterModule("CATEGORY_FORM_SERVICE_LIST");
  },
});
