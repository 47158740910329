var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('div',{staticClass:"d-flex"},[_c('v-autocomplete',{staticStyle:{"width":"250px !important"},attrs:{"items":_vm.customers,"placeholder":"Search by Email","dense":"","small-chips":"","prepend-icon":"mdi-filter-variant","item-value":"email","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":"","small":""},on:{"click":data.select,"click:close":function($event){return _vm.remove(data.item)}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.fullName)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.fullName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.email))])],1)]}}]),model:{value:(_vm.filters.email),callback:function ($$v) {_vm.$set(_vm.filters, "email", $$v)},expression:"filters.email"}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"0 2px"},attrs:{"outlined":"","tile":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter-variant")]),_c('span',[_vm._v("Order Status: "+_vm._s(_vm.filters.status))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',_vm._l((['all', 'pending', 'delivered', 'cancelled']),function(item){return _c('v-list-item',{key:item,on:{"click":function($event){return _vm.selectOrderStatus(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.search),expression:"filters.search"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Search by OrderNo"},domProps:{"value":(_vm.filters.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.filters, "search", $event.target.value)}}})])],1),_c('v-spacer'),_c('div',[_c('v-btn',{attrs:{"elevation":"0","text":"","small":""}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-export-variant")]),_vm._v(" Export ")],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredData,"items-per-page":5,"server-items-length":_vm.orderPage.total,"options":_vm.options,"loading":_vm.isLoading,"loading-text":"Loading orders","no-results-text":"No orders to display!","item-class":_vm.row_classes},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.orderID",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(("#" + (item.orderNo))))])])]}},{key:"item.orderDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("readableDate")(item.createdAt,"DD MMM YYYY | H:mm A")))])]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.client)?_c('span',[_vm._v(_vm._s(item.client.fullName))]):_vm._e()])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("numberWithCommas")(item.cost)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.status))])]}},{key:"item.payment_status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.paymentStatus))])]}},{key:"item.payment",fn:function(ref){
var item = ref.item;
return [(item.payment)?_c('span',[_vm._v(" "+_vm._s(((item.payment.currency) + " " + (item.payment.amount)))+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":"","close-on-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":("/order/" + (item._id))}},[_c('v-list-item-title',[_vm._v("View Order")])],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }