











































































































































import Vue from "vue";

import OrderModule from "@/store/modules/order";
import ClientModule from "@/store/modules/client";
import { createNamespacedHelpers } from "vuex";
import { Business, Client, Role } from "@/types";

const { mapGetters: orderGetters, mapActions: orderActions } =
  createNamespacedHelpers("ORDER_STORE");
const { mapGetters: orderClientsGetters, mapActions: orderClientActions } =
  createNamespacedHelpers("ORDER_CLIENTS");

export default Vue.extend<any, any, any, any>({
  name: "OrderList",
  props: {
    orderType: {
      type: String,
      default: "product",
    },
  },
  data: () => ({
    headers: [
      { text: "Order ID", value: "orderID", sortable: false },
      { text: "Customer", value: "client", sortable: false },
      { text: "Order Date", value: "orderDate", sortable: true },
      { text: "Payment Status", value: "payment_status", sortable: false },
      { text: "Paid Amount", value: "payment" },
      { text: "Order Total", value: "total", sortable: false },
      { text: "Delivery Status", value: "status", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    options: {} as { page: number; itemsPerPage: number },
    isLoading: false,
    filters: {
      search: "",
      status: "all",
      email: "",
    },
  }),
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
    ...orderGetters(["orderPage"]),
    ...orderClientsGetters(["clientPage"]),
    customers: function (): Client[] {
      return this.clientPage.docs;
    },
    filteredData: function (): any[] {
      const { search, status, email } = this.filters;
      const results = this.orderPage.docs
        .filter((order: any) => {
          if (email) {
            return order.client?.email.match(new RegExp(email, "i"));
          } else {
            return true;
          }
        })
        .filter((order: any) => {
          if (search) {
            return order.orderNo.match(new RegExp(search, "i"));
          } else {
            return true;
          }
        })
        .filter((order: any) => {
          if (status == "all") {
            return true;
          }

          if (status == "pending") {
            return order.status == "pending";
          }

          if (status == "delivered") {
            return order.status == "delivered";
          }

          if (status == "cancelled") {
            return order.status == "cancelled";
          }
        });
      return results;
    },
  },
  watch: {
    role() {
      this.loadOrders();
    },
    options: {
      deep: true,
      handler() {
        this.loadOrders();
      },
    },
  },
  created() {
    this.loadOrders();
  },
  methods: {
    ...orderActions(["fetchOrderList", "dropOrder"]),
    ...orderClientActions(["fetchClientList"]),
    customFilter(item: any, queryText: string) {
      const email = item.email.toLowerCase();
      const searchText = queryText.toLowerCase();
      return email.indexOf(searchText) > -1;
    },
    loadOrders() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        let limit = this.options.itemsPerPage ?? 10;
        let page = this.options.page || 1;
        if (limit === -1) limit = 1000000;
        let params = `?businessId=${bid}&page=${page}&limit=${limit}&type=${this.orderType}`;

        this.isLoading = true;
        // const params = `?businessId=${
        //   (this.role.business as Business)._id
        // }&page=${this.options.page || 1}&limit=${
        //   this.options.itemsPerPage || 10
        // }`;
        this.fetchOrderList(params).finally(() => (this.isLoading = false));
        this.fetchClientList(`?businessId=${bid}`);
      }
    },
    row_classes(item: any) {
      return item.read == false ? "light-green lighten-5" : "";
    },
    getProductsLinks(products: any[]) {
      return products.map((item) => {
        const options = item.options;
        return { title: item.product.title, id: item.product._id, options };
      });
    },
    deleteOrder(orderId: string) {
      this.dropOrder(orderId);
    },
    selectOrderStatus(status: string) {
      this.filters.status = status;
    },
    remove(item: any) {
      //
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("ORDER_STORE")) {
      this.$store.registerModule("ORDER_STORE", OrderModule);
    }
    if (!this.$store.hasModule("ORDER_CLIENTS")) {
      this.$store.registerModule("ORDER_CLIENTS", ClientModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("ORDER_STORE");
    this.$store.unregisterModule("ORDER_CLIENTS");
  },
});
