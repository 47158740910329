var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-dialog',{attrs:{"width":"490"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[(_vm.appointment)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Confirm to delete appointment ")]),_c('v-card-text',[_vm._v(" This action will delete appointment with "+_vm._s(_vm.appointment.employee.fullName)+" on "+_vm._s(_vm._f("readableDate")(_vm.appointment.startDate,"D/MM/YYYY HH:mm"))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(" Disagree ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.deleteAppointment(("/" + (_vm.appointment._id)));
            _vm.showDialog = false;}}},[_vm._v(" Agree ")])],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"width":"490"},model:{value:(_vm.showNotesDialog),callback:function ($$v) {_vm.showNotesDialog=$$v},expression:"showNotesDialog"}},[(_vm.appointment)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Appointment Notes ")]),_c('v-card-text',[(_vm.editingNotes)?_c('v-textarea',{attrs:{"label":"Notes","placeholder":"Enter notes"},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}}):_c('span',[_vm._v(_vm._s(_vm.appointment.notes))])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showNotesDialog = false;
            _vm.editingNotes = false;
            _vm.notes = '';}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showNotesDialog = false;
            _vm.editingNotes = false;
            _vm.saveNotes(_vm.appointment._id);}}},[_vm._v(" Save ")])],1)],1):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.appointmentPage.docs,"options":_vm.options,"server-items-length":_vm.appointmentPage.total,"no-data-text":"No appointments to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
            var item = ref.item;
return [(item.client)?_c('span',[_vm._v(_vm._s(item.client.fullName))]):_vm._e()]}},{key:"item.services",fn:function(ref){
            var item = ref.item;
return _vm._l((item.services),function(service,k){return _c('span',{key:k},[_vm._v(" "+_vm._s(service.name)+" ")])})}},{key:"item.employee",fn:function(ref){
            var item = ref.item;
return [(item.employee)?_c('span',[_vm._v(_vm._s(item.employee.fullName))]):_vm._e()]}},{key:"item.date",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableDate")(item.startDate,"D/MM/YYYY"))+" ")]}},{key:"item.time",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableDate")(item.startDate,"h:mm a"))+" - "+_vm._s(_vm._f("readableDate")(_vm.getEndDate(item),"h:mm a"))+" ")]}},{key:"item.paymentStatus",fn:function(ref){
            var item = ref.item;
return [(item.paymentStatus === 'completed')?_c('v-chip',{attrs:{"color":"#d3f9db"}},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(item.paymentStatus))])]):_vm._e(),(item.paymentStatus === 'pending')?_c('v-chip',{attrs:{"color":"#fcf1d0"}},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(item.paymentStatus))])]):_vm._e(),(item.paymentStatus === 'cancelled')?_c('v-chip',{attrs:{"color":"#facccc"}},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(item.paymentStatus))])]):_vm._e()]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3 mr-4",attrs:{"elevation":"1","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#f9cb9d"}},[_vm._v("mdi-notebook-edit-outline")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.editingNotes = false;
                _vm.appointment = item;
                _vm.showNotesDialog = true;}}},[_vm._v(" View notes ")])],1),_c('v-list-item',[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.appointment = item;
                _vm.notes = _vm.appointment.notes;
                _vm.editingNotes = true;
                _vm.showNotesDialog = true;}}},[_vm._v(" Add notes ")])],1)],1)],1),_vm._v("   "),_c('v-btn',{attrs:{"color":"red","outlined":"","rounded":""},on:{"click":function($event){_vm.appointment = item;
          _vm.showDialog = true;}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }